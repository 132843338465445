<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="2"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb30 m1340 flex-col-stretch">
                <LeftNav :leftIndex="8"></LeftNav>
                <div class="flex-grow-1 ml20 pt30 plr30 personal my_under">
                    <div class="fs20 fwb pb20">{{$t('myrecommendation')}}</div>
                    <div class="title_box flex-box fs16 col3">
                        <div class="title_item">{{$t('date')}}</div>
                        <div class="title_item ">{{$t('accountnumber')}}</div>
                        <div class="title_item"> {{$t('player_id')}}</div>
                        <div class="title_item">{{$t('price')}}</div>
                        <div class="title_item">{{$t('accessid')}}</div>
                    </div>
                    <div class="list_box flex-box fs16 col3" v-for="item in list">
                        <div class="list_item">{{item.createtime_text}} </div>
                        <div class="list_item ">{{item.idnum}}</div>
                        <div class="list_item">ID{{item.id}}</div>
                        <div class="list_item">{{item.level.price}}</div>
                        <div class="list_item">ID{{item.access_id}}</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<style>
    .hidden{
        display: none;
    }
</style>
<script>
import { numberToCurrency } from "../assets/filters";
import cookie from 'vue-cookies'
export default {
    filters: {
        numberToCurrency
    },
    data() {
        return {
            //人机验证 详情看网址 https://segmentfault.com/a/1190000023733698?sort=newest
            sitekey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
            user:'',
            list:{},
            articledetail:'',
            coinsrp:0,
            hidden:true,
            num:'',
            password:''

        };
    },
    created(){
        this.initdata()
    },
    methods: {
        config(){
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articledetail=res.data.member
                    this.coinsrp=res.data.coinsrp
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
						message: res.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
        },
       
      
       
        initdata(){
            this.getuser()
            this.loglist()
        },
        getuser(){
            if(cookie.get('sma_token')){
                this.$api.apiUserInfo({}).then(ret=>{
				if(ret.code==1){

					this.user = ret.data.userinfo;
				}
			})
            }
            
        },
        loglist(){
            this.$api.apiinveitelist({}).then(ret=>{
                if(ret.code==1){
                    this.list=ret.data
                }
            })
        },
    }
};
</script>
